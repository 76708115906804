import { PageProps } from 'gatsby';
import { CommentData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/comment.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCommentUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { commentId, parentCollectionName, parentId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayAdd={false}
        displayNavigation={false}
        displayView={false}
        docId={commentId}
        itemPathnamePrefix={`/espaces/${espace.id}/comments/${parentCollectionName}/${parentId}/`}
        model={
          new CommentData({
            params,
            parentCollectionName,
            parentId,
            espaceId: espace.id,
            labelFields: ['text'],
          })
        }
        name="comment"
        parentCollectionName={parentCollectionName}
        parentId={parentId}
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);
export default requireEspace(PageEspaceCommentUpdate);
